import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  useTheme
} from '@material-ui/core';

import { FormContext } from 'app/helpers/contexts/QuestionSet';

import useRoute from 'app/hooks/useRoute';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    button: {
      fontSize: '0.75rem'
    }
  }
}));

function Actions({ loading }) {
  const { employeeToken, campaignId, launchRecordToken } = useParams();
  const { form } = useContext(FormContext);
  const theme = useTheme();
  const classes = useStyles(theme);
  const dashboardPath = useRoute(
    '',
    employeeToken,
    campaignId,
    launchRecordToken
  );

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Link to={dashboardPath}>
          <Button
            className={classes.button}
            variant="contained"
            color={form.submitted ? 'primary' : 'default'}
          >
            Voltar
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading || form.submitted}
        >
          Responder
        </Button>
      </Grid>
      {loading && (
        <Grid item>
          <CircularProgress size={24} />
        </Grid>
      )}
    </Grid>
  );
}

Actions.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default Actions;
