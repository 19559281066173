import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';

import ProgressGauge from 'app/components/QuestionSet/Progress/Gauge';

const useStyles = makeStyles((theme) => ({
  presentation: {
    margin: theme.spacing(2, 0),
    '& > .MuiGrid-item': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '1.25rem'
    },
    paragraph: {
      fontSize: '0.85rem'
    }
  }
}));

function Presentation({ minScore, children }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      className={classes.presentation}
      container
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography
          className={classes.title}
          variant="h5"
          color="textSecondary"
          align="center"
        >
          {children}
        </Typography>
      </Grid>
      <Grid item>
        <ProgressGauge />
        <Typography
          className={classes.paragraph}
          variant="subtitle1"
          align="center"
        >
          Porcentagem mínima para aprovação: {minScore}%
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={classes.paragraph}
          variant="subtitle1"
          align="center"
        >
          Responda o questionário a seguir para identificarmos sua assimilação
          do conteúdo.
        </Typography>
      </Grid>
    </Grid>
  );
}

Presentation.propTypes = {
  minScore: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default Presentation;
