import React, { useContext } from 'react';

import {
  LinearProgress,
  makeStyles,
  useScrollTrigger,
  useTheme
} from '@material-ui/core';

import { FormContext, ProgressContext } from 'app/helpers/contexts/QuestionSet';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'sticky',
    top: '64px',
    width: `calc(100% + ${theme.spacing(4)}px)`,
    height: '8px',
    margin: `-${theme.spacing(2)}px -${theme.spacing(2)}px 0`,
    zIndex: '1200',
    transition: 'top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
  },
  slide: {
    top: '0px'
  },
  [theme.breakpoints.down('lg')]: {
    progress: {
      top: '64px'
    },
    slide: {
      top: '0px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    progress: {
      width: `calc(100% + ${theme.spacing(4)}px)`,
      margin: `-${theme.spacing(2)}px -${theme.spacing(2)}px 0`
    }
  },
  [theme.breakpoints.down('xs')]: {
    progress: {
      width: `calc(100% + ${theme.spacing(2)}px)`,
      margin: `-${theme.spacing(1)}px -${theme.spacing(1)}px 0`,
      top: '110px'
    },
    slide: {
      top: '0px'
    }
  }
}));

export default function Bar() {
  const theme = useTheme();
  const trigger = useScrollTrigger();
  const classes = useStyles(theme);
  const { form } = useContext(FormContext);
  const [progress] = useContext(ProgressContext);

  const getValue = () =>
    form.submitted ? 100 : (progress.answered / progress.total) * 100;

  return (
    <LinearProgress
      classes={{
        root: `${classes.progress} ${trigger ? classes.slide : ''}`
      }}
      variant="determinate"
      color="primary"
      value={getValue()}
    />
  );
}
