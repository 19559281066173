import employee, { campaign } from './api';

export default async (token, test = false) => {
  const api = test ? campaign : employee;

  const { data } = await api(token).get('/companies');
  return {
    minimumScore: data.minimum_score,
    desktopImg: data.desktop_image,
    mobileImg: data.mobile_image,
    logo: data.logo
  };
};
