export const employeeTrainingDashboardPath = (employeeToken) =>
  `/app/employees/${employeeToken}`;

export const employeeExtraContentDashboardPath = (employeeToken) =>
  `/app/employees/${employeeToken}/extra`;

export const employeeVideoPath = (employeeToken, launchRecordToken) =>
  `/app/employees/${employeeToken}/${launchRecordToken}/video`;

export const employeeQuestionSetPath = (employeeToken, launchRecordToken) =>
  `/app/employees/${employeeToken}/${launchRecordToken}/question_set`;

export const campaignTrainingDashboardPath = (campaignId, launchRecordToken) =>
  `/app/campaigns/${campaignId}/${launchRecordToken}`;

export const campaignExtraContentDashboardPath = (
  campaignId,
  launchRecordToken
) => `/app/campaigns/${campaignId}/${launchRecordToken}/extra`;

export const campaignVideoPath = (campaignId, launchRecordToken) =>
  `/app/campaigns/${campaignId}/${launchRecordToken}/video`;

export const campaignQuestionSetPath = (campaignId, launchRecordToken) =>
  `/app/campaigns/${campaignId}/${launchRecordToken}/question_set`;

export const route = (namespace, params, path = '') =>
  `/app/${namespace}/${params}/${path}`;
