import React, { useContext, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';

import NotFound from 'app/components/NotFound';
import Navbar from 'app/components/Navbar';
import Content from 'app/components/Content';
import Loading from 'app/components/Loading';

import AppNameContext from 'app/helpers/contexts/AppName';

import { getQuestionSet } from 'app/services/questionSet';

import {
  campaignTrainingDashboardPath,
  campaignExtraContentDashboardPath,
  campaignQuestionSetPath
} from 'app/helpers/routes';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    margin: `${theme.spacing(0.5)}px 0`
  },
  videoContainer: {
    minWidth: '300px',
    maxWidth: '1296px',
    width: '85%'
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  [theme.breakpoints.down('md')]: {
    videoContainer: {
      width: '90%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    videoContainer: {
      width: '95%'
    }
  }
}));

export default function Video() {
  const appName = useContext(AppNameContext);
  const [questionSet, setQuestionSet] = useState();
  const [notFound, setNotFound] = useState(false);
  const [finishedRequest, setFinishedRequest] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { campaignId, launchRecordToken } = useParams();

  useEffect(() => {
    getQuestionSet(campaignId, launchRecordToken, true)
      .then((data) => setQuestionSet(data))
      .catch(() => {
        setNotFound(true);
      })
      .finally(() => {
        setFinishedRequest(true);
      });
  }, [campaignId, launchRecordToken]);

  const dashboardUrl = (id) =>
    questionSet?.type === 'video'
      ? campaignExtraContentDashboardPath(id, launchRecordToken)
      : campaignTrainingDashboardPath(id, launchRecordToken);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : (
        <>
          <title>Treinamento | {appName}</title>
          <Navbar
            extraContent={questionSet?.type === 'video'}
            dashboardPath={campaignTrainingDashboardPath(
              campaignId,
              launchRecordToken
            )}
            extraContentDashboardPath={campaignExtraContentDashboardPath(
              campaignId,
              launchRecordToken
            )}
          />
          <Content>
            <Grid
              className={classes.gridContainer}
              container
              direction="column"
              alignItems="center"
              justifyContent="space-around"
              spacing={2}
            >
              <Grid className={classes.videoContainer} item>
                <Box className={classes.videoWrapper}>
                  {finishedRequest ? (
                    <iframe
                      className={classes.video}
                      src={questionSet?.url}
                      title="Vídeo de treinamento"
                      frameBorder="0"
                      allowFullScreen
                    />
                  ) : (
                    <Loading />
                  )}
                </Box>
              </Grid>
              {questionSet?.video && (
                <Grid item>
                  <Typography variant="button">
                    Assistiu o vídeo? Vamos para a prova!
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Link to={dashboardUrl(campaignId)}>
                      <Button size="medium" color="default" variant="contained">
                        VOLTAR
                      </Button>
                    </Link>
                  </Grid>
                  {questionSet?.type !== 'video' && (
                    <Grid item>
                      <Link
                        to={campaignQuestionSetPath(
                          campaignId,
                          launchRecordToken
                        )}
                      >
                        <Button
                          size="medium"
                          color="primary"
                          variant="contained"
                        >
                          INICIAR
                        </Button>
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Content>
        </>
      )}
    </>
  );
}
