import React from 'react';
import PropTypes from 'prop-types';

import { CardHeader } from '@material-ui/core';

function Header({ title, subheader }) {
  return (
    <CardHeader
      title={title}
      titleTypographyProps={{ variant: 'button' }}
      subheader={subheader}
      subheaderTypographyProps={{ variant: 'caption' }}
    />
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired
};

export default Header;
