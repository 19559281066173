import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useRoute from 'app/hooks/useRoute';

const useStyles = makeStyles((theme) => ({
  text3: {
    color: 'black',
    marginLeft: theme.spacing(10)
  },
  dashboardLink: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    width: '100%'
  }
}));

export default function Success({ show }) {
  const classes = useStyles();
  const { employeeToken, campaignId, launchRecordToken } = useParams();
  const dashboardPath = useRoute(
    '',
    employeeToken,
    campaignId,
    launchRecordToken
  );

  const handleClose = () => window.location.reload();

  return (
    <Dialog
      onClose={show}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.text3}
        onClose={show}
      >
        Parabéns!
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6"> Você passou no teste!</Typography>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Ver a Prova
        </Button>
        <Link to={dashboardPath}>
          <Button autoFocus>Voltar a dashboard</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}

Success.propTypes = {
  show: PropTypes.bool.isRequired
};
