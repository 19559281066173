import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  useTheme
} from '@material-ui/core';

import { FormContext } from 'app/helpers/contexts/QuestionSet';

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    marginTop: theme.spacing(0.5)
  },
  label: {
    fontSize: '0.85rem'
  },
  correct: {
    color: `${theme.palette.success.dark} !important`
  },
  incorrect: {
    color: `${theme.palette.error.dark} !important`
  },
  [theme.breakpoints.down('sm')]: {
    label: {
      fontSize: '0.75rem'
    }
  }
}));

function RadioAnswerGroup({ questionId, answers, correctAnswer }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { form, setForm } = useContext(FormContext);
  const handleChange = (event) => {
    setForm({
      ...form,
      [questionId]: {
        ...Object.fromEntries(
          Object.keys(form[questionId]).map((key) => [key, false])
        ),
        [event.target.value]: true
      }
    });
  };

  useEffect(() => {}, [correctAnswer]);

  const getValue = () =>
    Object.keys(form[questionId]).filter(
      (answer) => form[questionId][answer]
    )[0];
  const isChecked = (id) => form[questionId][id];
  const isCorrect = (id) => correctAnswer?.includes(id);

  const getClassName = (id) => {
    if (!form.submitted) return null;
    if (isCorrect(id)) return classes.correct;
    if (isChecked(id)) return classes.incorrect;

    return null;
  };

  return (
    <RadioGroup
      name={`${questionId}`}
      value={`${getValue()}`}
      onChange={handleChange}
    >
      {answers.map((answer) => (
        <FormControlLabel
          key={answer.id}
          className={classes.labelContainer}
          classes={{
            label: classes.label,
            disabled: getClassName(answer.id)
          }}
          label={answer.text}
          value={`${answer.id}`}
          control={
            <Radio className={getClassName(answer.id)} color="primary" />
          }
        />
      ))}
    </RadioGroup>
  );
}

RadioAnswerGroup.propTypes = {
  questionId: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  correctAnswer: PropTypes.arrayOf(PropTypes.number)
};

RadioAnswerGroup.defaultProps = {
  correctAnswer: null
};

export default RadioAnswerGroup;
