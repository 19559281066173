import React from 'react';
import PropTypes from 'prop-types';

import {
  Card as MuiCard,
  Divider,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import CardHeader from './CardHeader';
import CardContent from './CardContent';

const useStyles = makeStyles((theme) => ({
  grid: {
    height: '80px'
  },
  gridItem: {
    flexGrow: 1
  },
  [theme.breakpoints.down('sm')]: {
    grid: {
      height: '320px',
      width: '240px'
    }
  }
}));

function Card({ title, subheader, date, status, token }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiCard>
      <Grid
        container
        direction={smallDevice ? 'column' : 'row'}
        className={classes.grid}
      >
        <CardHeader title={title} subheader={subheader} />
        <Divider
          variant="middle"
          flexItem={!smallDevice}
          orientation={smallDevice ? 'horizontal' : 'vertical'}
        />
        <CardContent date={date} status={status} token={token} />
      </Grid>
    </MuiCard>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired
};

export default Card;
