import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import {
  AppBar,
  Grid,
  Slide,
  Tabs,
  Tab,
  useScrollTrigger,
  makeStyles,
  useTheme
} from '@material-ui/core';

import Logo from 'app/images/diver-logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '64px'
  },
  grid: {
    height: '100%',
    maxWidth: '1200px'
  },
  logo: {
    height: '100%'
  },
  dashboardLink: {
    height: '45%',
    padding: `0 ${theme.spacing(1.5)}px`
  },
  tab: {
    textTransform: 'none'
  },
  [theme.breakpoints.down('lg')]: {
    grid: {
      padding: `0 ${theme.spacing(2)}px`
    }
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      height: '110px'
    },
    grid: {
      height: '100%'
    },
    tabs: {
      width: '100%',
      height: '55%'
    },
    dashboardLink: {
      display: 'flex',
      alignItems: 'center',
      height: '45%',
      width: '100%'
    },
    logo: {
      height: '55%'
    }
  }
}));

function Navbar({
  extraContent = false,
  dashboardPath,
  extraContentDashboardPath
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [tabIndex, setTabIndex] = useState(0);
  const trigger = useScrollTrigger();

  const handleTabChange = (_event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setTabIndex(extraContent ? 1 : 0);
  }, [extraContent, tabIndex]);

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar color="default" className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.grid}
        >
          <Link className={classes.dashboardLink} to={dashboardPath}>
            <img className={classes.logo} src={Logo} alt="logo" />
          </Link>
          <Tabs
            className={classes.tabs}
            value={tabIndex}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab
              className={classes.tab}
              component={Link}
              to={dashboardPath}
              label="Treinamentos"
            />
            <Tab
              className={classes.tab}
              component={Link}
              to={extraContentDashboardPath}
              label="Conteúdo Extra"
            />
          </Tabs>
        </Grid>
      </AppBar>
    </Slide>
  );
}

Navbar.propTypes = {
  extraContent: PropTypes.bool,
  dashboardPath: PropTypes.string.isRequired,
  extraContentDashboardPath: PropTypes.string.isRequired
};

Navbar.defaultProps = {
  extraContent: false
};

export default Navbar;
