import React from 'react';
import PropTypes from 'prop-types';

import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useRoute from 'app/hooks/useRoute';

const useStyles = makeStyles((theme) => ({
  text3: {
    color: 'black',
    marginLeft: theme.spacing(23)
  },
  dashboardLink: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    width: '100%'
  }
}));

export default function Result({ show }) {
  const classes = useStyles();
  const { employeeToken, campaignId, launchRecordToken } = useParams();
  const dashboardPath = useRoute(
    '',
    employeeToken,
    campaignId,
    launchRecordToken
  );

  return (
    <Dialog
      onClose={show}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.text3}
        onClose={show}
      >
        Refaça o teste!
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">
          Infelizmente você não atingiu a nota mínima. Refaça o teste
        </Typography>
      </DialogContent>
      <DialogActions>
        <Link to={dashboardPath}>
          <Button autoFocus>Voltar a dashboard</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}

Result.propTypes = {
  show: PropTypes.bool.isRequired
};
