import React, { useContext } from 'react';

import { Typography, makeStyles, useTheme } from '@material-ui/core';
import { FormContext, ProgressContext } from 'app/helpers/contexts/QuestionSet';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    paragraph: {
      fontSize: '0.85rem'
    }
  }
}));

export default function Gauge() {
  const { form, score } = useContext(FormContext);
  const [progress] = useContext(ProgressContext);
  const theme = useTheme();
  const classes = useStyles(theme);

  const submittedText = `Você acertou ${score} de ${progress.total} questões`;
  const unSubmittedText = `Questões respondidas: ${progress.answered} de ${progress.total}`;

  return (
    <Typography
      className={classes.paragraph}
      variant="subtitle1"
      align="center"
    >
      {form.submitted ? submittedText : unSubmittedText}
    </Typography>
  );
}
