import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '64px',
    background: theme.palette.background.default,
    minHeight: '100vh',
    height: '100%'
  },
  content: {
    maxWidth: '1200px',
    width: '100%',
    minHeight: '100%',
    background: theme.palette.background.default,
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(7)}px`
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      paddingTop: '110px'
    }
  }
}));

function Content({ children }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid className={classes.container} container justifyContent="center">
      <Paper className={classes.content} variant="elevation" elevation={0}>
        {children}
      </Paper>
    </Grid>
  );
}

Content.propTypes = {
  children: PropTypes.node.isRequired
};

export default Content;
