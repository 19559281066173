import {
  getCorrectAnswers,
  getReport,
  getSubmitParams
} from 'app/utils/questionSet';

import employee, { campaign } from './api';

export const getQuestionSet = async (id, launchRecordToken, test = false) => {
  const api = test ? campaign : employee;
  const { data } = await api(id).get(`/trainings/${launchRecordToken}`);

  const correctAnswers = getCorrectAnswers(data.correct_answers);
  const report = getReport(data.report);

  return {
    ...data,
    ...data.question_set,
    correctAnswers,
    report
  };
};

export const submitQuestionSet = async (
  id,
  launchRecordToken,
  params,
  test = true
) => {
  const api = test ? campaign : employee;
  const { data } = await api(id).put(`/trainings/${launchRecordToken}`, {
    questions: getSubmitParams(params)
  });

  return data;
};
