import axios from 'axios';

export default (employeeToken = null) => {
  const api = axios.create({ baseURL: '/api/v1/employees' });

  api.interceptors.request.use((config) => {
    if (employeeToken) config.headers['Access-Token'] = employeeToken;
    return config;
  });

  return api;
};

export const campaign = (campaignId = null) => {
  const api = axios.create({ baseURL: '/api/v1/campaigns' });

  api.interceptors.request.use((config) => {
    if (campaignId) config.headers['Access-Token'] = campaignId;
    return config;
  });

  return api;
};
