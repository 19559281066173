import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getQuestionSetProgress } from 'app/utils/questionSet';

import { FormContext, ProgressContext } from 'app/helpers/contexts/QuestionSet';

function Calculator({ children }) {
  const { form } = useContext(FormContext);
  const [progress, setProgress] = useState({
    answered: 0,
    total: 0
  });

  useEffect(() => {
    const { answered, total } = getQuestionSetProgress(form);

    setProgress({
      ...progress,
      answered,
      total
    });
  }, [form]);

  return (
    <ProgressContext.Provider value={[progress, setProgress]}>
      {children}
    </ProgressContext.Provider>
  );
}

Calculator.propTypes = {
  children: PropTypes.node.isRequired
};

export default Calculator;
