import React from 'react';
import PropTypes from 'prop-types';

import { Grid, useMediaQuery, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '24px'
  },
  img: {
    maxWidth: '1600px',
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    img: {
      maxHeight: '625px',
      maxWidth: '497px'
    }
  }
}));

function Presentation({ desktopImg, mobileImg }) {
  const theme = useTheme();
  const classes = useStyles();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      alignItems="center"
    >
      <img
        className={classes.img}
        src={smallDevice ? mobileImg : desktopImg}
        alt="Presentation"
      />
    </Grid>
  );
}

Presentation.propTypes = {
  desktopImg: PropTypes.string.isRequired,
  mobileImg: PropTypes.string.isRequired
};

export default Presentation;
