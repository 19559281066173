import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#5d72f5',
      main: '#0a47c1',
      dark: '#00218f',
      contrastText: '#f2f2f2'
    },
    secondary: {
      light: '#6cffff',
      main: '#00d0ff',
      dark: '#009fcc',
      contrastText: '#000000'
    },
    grey: {
      100: '#1d1b29',
      300: '#cacaca'
    },
    error: {
      main: '#C61414'
    },
    success: {
      main: '#20824C'
    },
    background: {
      default: '#f2f2f2'
    },
    text: {
      primary: '#000000',
      secondary: '#4a4954'
    },
    divider: '#226795'
  },
  typography: {
    fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    button: {
      fontWeight: '700'
    }
  }
});

theme.overrides = {
  MuiCard: {
    root: {
      borderRadius: '10px',
      background: 'white'
    }
  },
  MuiCardHeader: {
    root: {
      height: '100%',
      flex: 3,
      [theme.breakpoints.down('sm')]: {
        flex: 1
      }
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      wordBreak: 'break-word',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
      }
    },
    subheader: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '0.9rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        fontSize: '1rem'
      }
    },
    title: {
      fontSize: '0.7rem',
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1',
      color: theme.palette.text.secondary,
      '@media (max-width:1280px)': {
        fontSize: '0.6rem'
      }
    }
  },
  MuiCardContent: {
    root: {
      flex: 2,
      height: '100%',
      '&:last-child': {
        paddingBottom: theme.spacing(1.5)
      },
      [theme.breakpoints.down('sm')]: {
        flex: 1
      }
    }
  },
  MuiCardActions: {
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0'
    }
  },
  MuiDivider: {
    root: {
      height: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5)
    },
    vertical: {
      width: theme.spacing(0.5),
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25)
    }
  },
  MuiAppBar: {
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '64px',
      width: '100%',
      background: theme.palette.primary.main
    }
  },
  MuiTabs: {
    root: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '48px'
      }
    },
    flexContainer: {
      height: '100%'
    }
  },
  MuiTab: {
    root: {
      fontSize: '.8rem'
    }
  },
  MuiFormLabel: {
    root: {
      ...theme.typography.h6,
      marginBottom: theme.spacing(1),
      '&$focused': {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold
      }
    }
  },
  MuiFormControlLabel: {
    root: {
      marginBottom: theme.spacing(0.5)
    }
  }
};

export default theme;
