import React, { useContext, useEffect, useState } from 'react';

import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';

import NotFound from 'app/components/NotFound';
import Loading from 'app/components/Loading';
import Navbar from 'app/components/Navbar';
import Content from 'app/components/Content';
import Presentation from 'app/components/Presentation';
import CardGrid from 'app/components/CardGrid';

import {
  campaignTrainingDashboardPath,
  campaignExtraContentDashboardPath
} from 'app/helpers/routes';
import AppNameContext from 'app/helpers/contexts/AppName';

import getCampaign from 'app/services/campaign';
import getCompany from 'app/services/company';

export default function Dashboard() {
  const appName = useContext(AppNameContext);
  const [user, setUser] = useState();
  const [company, setCompany] = useState();
  const [notFound, setNotFound] = useState(false);
  const [finishedRequest, setFinishedRequest] = useState(false);
  const { campaignId, launchRecordToken } = useParams();
  const { path } = useRouteMatch();
  const match = useRouteMatch(
    campaignExtraContentDashboardPath(':campaignId', ':launchRecordToken')
  );

  useEffect(() => {
    getCampaign(campaignId, launchRecordToken)
      .then((data) => setUser(data))
      .then(() => getCompany(campaignId, true))
      .then((data) => setCompany(data))
      .catch(() => setNotFound(true))
      .finally(() => setFinishedRequest(true));
  }, [campaignId]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : (
        <>
          <title>Dashboard | {appName}</title>
          <Navbar
            extraContent={!!match}
            dashboardPath={campaignTrainingDashboardPath(
              campaignId,
              launchRecordToken
            )}
            extraContentDashboardPath={campaignExtraContentDashboardPath(
              campaignId,
              launchRecordToken
            )}
          />
          <Content>
            {finishedRequest ? (
              <>
                <Presentation
                  desktopImg={company?.desktopImg || ''}
                  mobileImg={company?.mobileImg || ''}
                />
                <Switch>
                  <Route path={`${path}/extra`}>
                    <CardGrid
                      test
                      type="extra-content"
                      employeeName={user.name}
                      id={campaignId}
                      launchRecordToken={launchRecordToken}
                    />
                  </Route>
                  <Route path={path}>
                    <CardGrid
                      test
                      type="training"
                      employeeName={user.name}
                      id={campaignId}
                      launchRecordToken={launchRecordToken}
                    />
                  </Route>
                </Switch>
              </>
            ) : (
              <Loading />
            )}
          </Content>
        </>
      )}
    </>
  );
}
