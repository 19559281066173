import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import AppNameContext from 'app/helpers/contexts/AppName';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw'
  },
  text: {
    marginBottom: theme.spacing(3)
  }
}));

export default function NotFound() {
  const appName = useContext(AppNameContext);
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <title>Não encontrado | {appName}</title>
      <Grid item>
        <Grid
          className={classes.text}
          container
          direction={smallDevice ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant={smallDevice ? 'h5' : 'h4'}>404</Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Typography variant={smallDevice ? 'body2' : 'h5'}>
              Não encontramos a página que você procura
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Voltar
        </Button>
      </Grid>
    </Grid>
  );
}
