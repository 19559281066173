import React, { useContext, useEffect, useState } from 'react';

import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';

import NotFound from 'app/components/NotFound';
import Loading from 'app/components/Loading';
import Navbar from 'app/components/Navbar';
import Content from 'app/components/Content';
import Presentation from 'app/components/Presentation';
import CardGrid from 'app/components/CardGrid';

import {
  employeeExtraContentDashboardPath,
  employeeTrainingDashboardPath
} from 'app/helpers/routes';
import AppNameContext from 'app/helpers/contexts/AppName';

import getEmployee from 'app/services/employee';
import getCompany from 'app/services/company';

export default function Dashboard() {
  const appName = useContext(AppNameContext);
  const [employee, setEmployee] = useState();
  const [company, setCompany] = useState();
  const [notFound, setNotFound] = useState(false);
  const [finishedRequest, setFinishedRequest] = useState(false);
  const { employeeToken } = useParams();
  const { path } = useRouteMatch();
  const match = useRouteMatch(
    employeeExtraContentDashboardPath(':employeeToken')
  );

  useEffect(() => {
    getEmployee(employeeToken)
      .then((data) => setEmployee(data))
      .then(() => getCompany(employeeToken))
      .then((data) => setCompany(data))
      .catch(() => setNotFound(true))
      .finally(() => setFinishedRequest(true));
  }, [employeeToken]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : (
        <>
          <title>Dashboard | {appName}</title>
          <Navbar
            extraContent={!!match}
            dashboardPath={employeeTrainingDashboardPath(employeeToken)}
            extraContentDashboardPath={employeeExtraContentDashboardPath(
              employeeToken
            )}
          />
          <Content>
            {finishedRequest ? (
              <>
                <Presentation
                  desktopImg={company?.desktopImg || ''}
                  mobileImg={company?.mobileImg || ''}
                />
                <Switch>
                  <Route path={`${path}/extra`}>
                    <CardGrid
                      type="extra-content"
                      employeeName={employee.name}
                      id={employeeToken}
                    />
                  </Route>
                  <Route path={path}>
                    <CardGrid
                      type="training"
                      employeeName={employee.name}
                      id={employeeToken}
                    />
                  </Route>
                </Switch>
              </>
            ) : (
              <Loading />
            )}
          </Content>
        </>
      )}
    </>
  );
}
