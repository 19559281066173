import React, { useContext, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import NotFound from 'app/components/NotFound';
import Navbar from 'app/components/Navbar';
import Content from 'app/components/Content';
import ProgressBar from 'app/components/QuestionSet/Progress/Bar';
import ProgressCalculator from 'app/components/QuestionSet/Progress/Calculator';
import Presentation from 'app/components/QuestionSet/Presentation';
import Form from 'app/components/QuestionSet/Form';
import Loading from 'app/components/Loading';

import { FormContext, ReportContext } from 'app/helpers/contexts/QuestionSet';
import AppNameContext from 'app/helpers/contexts/AppName';
import {
  employeeTrainingDashboardPath,
  employeeExtraContentDashboardPath,
  employeeVideoPath
} from 'app/helpers/routes';

import { getQuestionSet } from 'app/services/questionSet';
import getCompany from 'app/services/company';

import { getFormInitialValues } from 'app/utils/questionSet';

export default function QuestionSet() {
  const appName = useContext(AppNameContext);
  const [company, setCompany] = useState();
  const [questionSet, setQuestionSet] = useState(null);
  const [form, setForm] = useState(null);
  const [report, setReport] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [score, setScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalFinished, setShowModalFinished] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const { employeeToken, launchRecordToken } = useParams();
  const history = useHistory();

  useEffect(() => {
    getCompany(employeeToken)
      .then((data) => setCompany(data))
      .then(() => getQuestionSet(employeeToken, launchRecordToken))
      .then((data) => {
        setQuestionSet(data);

        if (data.type !== 'video') {
          setCorrectAnswers(data.correctAnswers);
          setShowModal(!data.finished);
          const formInitialValues = getFormInitialValues(
            data.answered
              ? {
                  finished: data.finished,
                  questions: data.answered,
                  answered: true
                }
              : data
          );
          setShowModalFinished(data.finished);
          setForm(formInitialValues);
          setScore(data.score || 0);
          setReport(data.report);
        } else {
          history.push(employeeVideoPath(employeeToken, launchRecordToken));
        }
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [employeeToken, launchRecordToken, form?.submitted]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : (
        <>
          <title>Questionário | {appName}</title>
          <Navbar
            dashboardPath={employeeTrainingDashboardPath(employeeToken)}
            extraContentDashboardPath={employeeExtraContentDashboardPath(
              employeeToken
            )}
          />
          <Content>
            {form ? (
              <FormContext.Provider
                value={{
                  form,
                  setForm,
                  questionSet,
                  setQuestionSet,
                  score,
                  setScore
                }}
              >
                <ProgressCalculator>
                  <ProgressBar />
                  <Presentation minScore={company.minimumScore}>
                    <strong>{questionSet.name}</strong>
                  </Presentation>

                  <ReportContext.Provider
                    value={{
                      report,
                      setReport,
                      correctAnswers,
                      setCorrectAnswers
                    }}
                  >
                    <Form
                      showModalFinished={showModalFinished}
                      showModal={showModal}
                      questions={questionSet.questions}
                    />
                  </ReportContext.Provider>
                </ProgressCalculator>
              </FormContext.Provider>
            ) : (
              <Loading />
            )}
          </Content>
        </>
      )}
    </>
  );
}
