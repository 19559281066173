import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import Card from 'app/components/Card';
import Loading from 'app/components/Loading';

import { getTrainings, getTestTraining } from 'app/services/trainings';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(3)
  },
  presentation: {
    alignSelf: 'center',
    fontSize: '1.1rem'
  },
  [theme.breakpoints.down('sm')]: {
    presentation: {
      fontSize: '1rem'
    }
  }
}));

function CardGrid({ type, employeeName, test, id, launchRecordToken }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [items, setItems] = useState();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const trainings = test ? getTestTraining : getTrainings;

    trainings(id, type === 'extra-content', launchRecordToken)
      .then((data) => setItems(data))
      .catch(() => setItems([]));
  }, [type]);

  return items ? (
    <>
      {type === 'training' ? (
        <Typography className={classes.presentation}>
          Olá, {employeeName}! Você foi convocado para{' '}
          <strong>{items.length}</strong> treinamento(s)
        </Typography>
      ) : (
        <Typography className={classes.presentation}>
          Olá, {employeeName}! Você recebeu <strong>{items.length}</strong>{' '}
          conteúdo(s) extra(s)
        </Typography>
      )}
      <Grid
        className={classes.grid}
        container
        direction={smallDevice ? 'row' : 'column'}
        justifyContent="center"
        spacing={3}
      >
        {items.map((item) => (
          <Grid key={item.token} item>
            <Card
              key={item.token}
              title={type === 'training' ? 'treinamento' : 'conteúdo extra'}
              subheader={item.name}
              date={item.sent_at}
              status={item.status}
              token={item.token}
            />
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <Loading />
  );
}

CardGrid.propTypes = {
  type: PropTypes.oneOf(['extra-content', 'training']).isRequired,
  employeeName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  test: PropTypes.bool,
  launchRecordToken: PropTypes.string
};

CardGrid.defaultProps = {
  test: false,
  launchRecordToken: null
};

export default CardGrid;
