import formatDate from 'app/utils/date';
import api, { campaign } from './api';

export const getTrainings = (employeeToken, extra) =>
  api(employeeToken)
    .get(`/trainings?extra=${extra}`)
    .then(({ data }) =>
      data
        ? data.map((training) => ({
            ...training,
            sent_at: formatDate(training.sent_at)
          }))
        : []
    );

export const getTestTraining = (id, extra, launchRecordToken) =>
  campaign(id)
    .get(`/trainings/${launchRecordToken}?extra=${extra}`)
    .then(({ data }) =>
      data
        ? [
            {
              ...data,
              sent_at: formatDate(data.sent_at)
            }
          ]
        : []
    );
