import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';

import AppNameContext from './helpers/contexts/AppName';

import theme from './helpers/themes';
import {
  employeeTrainingDashboardPath,
  employeeVideoPath,
  employeeQuestionSetPath,
  campaignTrainingDashboardPath,
  campaignVideoPath,
  campaignQuestionSetPath
} from './helpers/routes';

import EmployeeDashboard from './pages/employees/Dashboard';
import EmployeeVideo from './pages/employees/Video';
import EmployeeQuestionSet from './pages/employees/QuestionSet';
import CampaignDashboard from './pages/campaigns/Dashboard';
import CampaignVideo from './pages/campaigns/Video';
import CampaignQuestionSet from './pages/campaigns/QuestionSet';
import NotFound from './components/NotFound';

const appName = 'Diver';

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppNameContext.Provider value={appName}>
          <Router>
            <Switch>
              <Route
                path={employeeVideoPath(':employeeToken', ':launchRecordToken')}
                component={EmployeeVideo}
              />
              <Route
                path={employeeQuestionSetPath(
                  ':employeeToken',
                  ':launchRecordToken'
                )}
                component={EmployeeQuestionSet}
              />
              <Route
                path={employeeTrainingDashboardPath(':employeeToken')}
                component={EmployeeDashboard}
              />
              <Route
                path={campaignVideoPath(':campaignId', ':launchRecordToken')}
                component={CampaignVideo}
              />
              <Route
                path={campaignQuestionSetPath(
                  ':campaignId',
                  ':launchRecordToken'
                )}
                component={CampaignQuestionSet}
              />
              <Route
                path={campaignTrainingDashboardPath(
                  ':campaignId',
                  ':launchRecordToken'
                )}
                component={CampaignDashboard}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </AppNameContext.Provider>
      </ThemeProvider>
    </>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.body.appendChild(document.createElement('main'))
  );
});
