import { route } from 'app/helpers/routes';
import { useLocation } from 'react-router-dom';

export default function useRoute(path = '', ...params) {
  const location = useLocation();
  const namespace = location.pathname.split('/')[2];
  const paramsString = params.filter(Boolean).join('/');

  return route(namespace, paramsString, path);
}
