import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import {
  Button,
  CardContent,
  CardActions,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import useRoute from 'app/hooks/useRoute';

const useStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    height: '100%'
  },
  actions: {
    alignSelf: 'center'
  },
  label: {
    minWidth: '100px',
    fontSize: '0.7rem',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1',
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('md')]: {
    label: {
      fontSize: '0.6rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    label: {
      marginTop: '0.5rem'
    }
  }
}));

function Content({ date, status, token }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { employeeToken, campaignId } = useParams();
  const videoPath = useRoute('video', employeeToken, campaignId, token);

  return (
    <CardContent className={classes.root}>
      <Grid
        className={classes.grid}
        container
        direction={smallDevice ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={smallDevice ? 'flex-start' : 'center'}
      >
        <Grid item>
          <Typography className={classes.label}>CONVOCADO EM</Typography>
          <Typography variant="button">{date}</Typography>
        </Grid>
        <Grid className={classes.actions} item>
          <CardActions>
            <Link to={videoPath}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={status === 'Completo'}
              >
                {status === 'Completo' ? 'Completo' : 'Iniciar'}
              </Button>
            </Link>
          </CardActions>
        </Grid>
      </Grid>
    </CardContent>
  );
}

Content.propTypes = {
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired
};

export default Content;
