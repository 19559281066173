import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  useTheme
} from '@material-ui/core';

import { FormContext } from 'app/helpers/contexts/QuestionSet';

import CheckboxAnswerGroup from './CheckboxAnswerGroup';
import RadioAnswerGroup from './RadioAnswerGroup';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    margin: `${theme.spacing(3)}px 0`,
    '&.MuiGrid-item': {
      padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`
    },
    borderRadius: 10
  },
  label: {
    fontSize: '1rem'
  },
  correct: {
    color: `${theme.palette.success.main} !important`
  },
  [theme.breakpoints.down('sm')]: {
    label: {
      fontSize: '0.85rem'
    }
  }
}));

function Question({ question, error, correctAnswer, loading }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { form } = useContext(FormContext);

  return (
    <Grid className={classes.root} item key={question.id}>
      <FormControl
        error={error}
        component="fieldset"
        margin="dense"
        required
        disabled={loading || form.submitted}
        fullWidth
      >
        <FormLabel
          className={`${classes.label} ${
            !error && correctAnswer ? classes.correct : ''
          }`}
          component="legend"
        >
          <strong>Pergunta {question.id}: </strong>
          {question.text}
        </FormLabel>
        <FormHelperText
          className={!error && correctAnswer ? classes.correct : ''}
        >
          Marque a(s) opção(ões) correta(s):
        </FormHelperText>
        {question.multiple ? (
          <CheckboxAnswerGroup
            questionId={question.id}
            answers={question.answers}
            correctAnswer={correctAnswer}
          />
        ) : (
          <RadioAnswerGroup
            questionId={question.id}
            answers={question.answers}
            correctAnswer={correctAnswer}
          />
        )}
      </FormControl>
    </Grid>
  );
}

Question.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    multiple: PropTypes.bool.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string
      })
    )
  }).isRequired,
  error: PropTypes.bool,
  correctAnswer: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool.isRequired
};

Question.defaultProps = {
  error: null,
  correctAnswer: null
};

export default Question;
