export const getFormInitialValues = (questionSet) => {
  const form = questionSet.questions.map((question) => {
    const answers = question.answers.map((answer) => [
      answer.id,
      !!questionSet.answered
    ]);

    return [question.id, Object.fromEntries(answers)];
  });

  form.push(['submitted', questionSet.finished]);
  return Object.fromEntries(form);
};

export const getQuestionSetProgress = (form) => {
  const questionIds = Object.keys(form).filter(
    (key) => !Number.isNaN(parseInt(key, 10))
  );
  const answered = questionIds.filter((questionId) => {
    const answer = form[questionId];
    const answerIds = Object.keys(answer);

    return answerIds.filter((answerId) => answer[answerId]).length;
  });

  return { answered: answered.length, total: questionIds.length };
};

export const getCorrectAnswers = (correctAnswers = []) =>
  Object.fromEntries(
    correctAnswers.map((question) => [
      question.id,
      question.answers.map((answer) => answer.id)
    ])
  );

export const getReport = (report = []) =>
  Object.fromEntries(report.map((errorObj) => [errorObj.id, errorObj.error]));

export const getSubmitParams = (form) => {
  const questionIds = Object.keys(form).filter(
    (key) => !Number.isNaN(parseInt(key, 10))
  );
  return questionIds.map((questionId) => {
    let answers = [];
    const question = form[questionId];

    answers = Object.keys(question)
      .filter((answerId) => question[answerId])
      .map((answerId) => ({ id: parseInt(answerId, 10) }));

    return { id: parseInt(questionId, 10), answers };
  });
};
