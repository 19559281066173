import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';

import { Alert } from '@material-ui/lab';
import { CircularProgress, Grid, Snackbar } from '@material-ui/core';

import {
  FormContext,
  ProgressContext,
  ReportContext
} from 'app/helpers/contexts/QuestionSet';

import { submitQuestionSet } from 'app/services/questionSet';
import ModalResult from './ModalResult';
import ModalSuccess from './ModalSuccess';

import Question from './Question';
import Actions from './Actions';

function Form({ showModalFinished, showModal, questions, test }) {
  const [alert, setAlert] = useState({
    open: false,
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const { form, setForm } = useContext(FormContext);
  const { correctAnswers, report } = useContext(ReportContext);
  const [progress] = useContext(ProgressContext);
  const { campaignId, employeeToken, launchRecordToken } = useParams();
  const [clicked, setClicked] = useState(false);

  const modal = <ModalResult show={showModal} />;
  const modalPassed = <ModalSuccess show={showModalFinished} />;

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (progress.answered !== progress.total) {
      setAlert({
        message: 'Por favor responda o questionário antes de enviar',
        open: true
      });
      setLoading(false);

      return;
    }

    const id = test ? campaignId : employeeToken;

    submitQuestionSet(id, launchRecordToken, form, test)
      .then(() => {
        setClicked(true);
        setForm({ ...form, submitted: true });
      })
      .catch(() => {
        setAlert({
          open: true,
          message:
            'Houve um erro ao enviar o formulário, tente novamente mais tarde'
        });
      })
      .finally(() => setLoading(false));
  };

  const handleAlertClose = (_event, reason) => {
    if (reason === 'clickaway') return;

    setAlert({ ...alert, open: false });
  };

  return (
    <form onSubmit={handleSubmit}>
      {Object.keys(form).length ? (
        <>
          {showModal === true && !form.submitted && clicked ? modal : ''}
          {showModalFinished === true && form.submitted && clicked
            ? modalPassed
            : ''}
          <Grid container direction="column" spacing={4}>
            {questions.map((question) => (
              <Question
                key={question.id}
                question={question}
                error={report?.[question.id]}
                correctAnswer={correctAnswers?.[question.id]}
                loading={loading}
              />
            ))}
            <Grid item>
              <Actions loading={loading} />
            </Grid>
          </Grid>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity="error" variant="filled">
              {alert.message}
            </Alert>
          </Snackbar>
        </>
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

Form.propTypes = {
  showModal: PropTypes.bool.isRequired,
  showModalFinished: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          text: PropTypes.string
        })
      )
    })
  ).isRequired,
  test: PropTypes.bool
};

Form.defaultProps = {
  test: false
};

export default Form;
